import React from "react"
import PrimaryLayout from "../layouts/PrimaryLayout"
import styles from "../css/error.module.css"
import { Link } from "gatsby"
import Banner from '../components/Common/Banner'
import SEO from '../components/Common/SEO'

export default function error() {
    return <PrimaryLayout>
        <SEO title="Página no encontrada. Error 404" />
        <header className={styles.error}>
            <Banner title="Página no encontrada" info="La típica y más técnicamente conocida como Página 404">
                <Link to="/" className="btn-white">Volver a la Home</Link>
            </Banner>
        </header>
    </PrimaryLayout>
}
